import { useState, useEffect } from "react";
import config from "src/commons/config-hoc";
import { PageContent } from "@ra-lib/admin";
import moment from "moment";
import { getQuery } from "@ra-lib/admin";
import peopleIcon from "../../image/people_improvement.png";
import checkedIcon from "../../image/checked.png";
import goodIcon from "../../image/good.png";
import unruleIcon from "../../image/unrule.png";
import uncheckIcon from "../../image/uncheck.png";
import { dateButtonType } from "../report/constant";
import { getDate } from "../report/utils";
import {
  Empty,
  Form,
  Spin,
  Breadcrumb,
  DatePicker,
  Space,
  TreeSelect,
  Select,
  message,
  Menu,
  Tooltip,
  Button,
} from "antd";
import style from "./style.less";

import { AppstoreOutlined, SettingOutlined } from "@ant-design/icons";

const RangePicker = DatePicker.RangePicker;
const { TreeNode } = TreeSelect;

const renderEmpty = () => {
  return (
    <div style={{ flex: 1 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Empty style={{ width: "100%" }} description="暂无数据" />
      </div>
    </div>
  );
};
export default config({
  path: "/front/employees-improvement",
  title: "员工持续改进",
})(function EmployeeImprovement(props) {
  const [switchValue, setSwitchValue] = useState("1");
  const [loading, setloading] = useState(true);
  const { userId, startDate, endDate } = getQuery();
  const [dates, setDates] = useState([
    startDate ? moment(startDate, "YYYY-MM-DD") : null,
    endDate ? moment(endDate, "YYYY-MM-DD") : null,
  ]);

  const [departmentId, setDepartmentId] = useState("");
  const [deptName, setDeptName] = useState("");
  const [options, setOptions] = useState([]);
  const [treeDData, setTreeDData] = useState([]);
  const [drillDownKey, setDrillDownKey] = useState("");

  const [rules, setRules] = useState([]);
  const [institutionId, setInstitutionId] = useState("");
  // const [selectedRule, setSelectedRule] = useState("");

  const [cards, setCards] = useState([]);

  const [employee, setEmployee] = useState({
    value: userId || "",
  });

  const handleChangeDate = (type,dateType) => {
    const dataTypeEnum = [1, 2, 3, 4, 5, 6];
    if (!dataTypeEnum.includes(dateType)) {
      const nowDate = getDate(type);
      setDates(nowDate);
    } else {
      getDateCycle(dateType);
    }
  };
  const getDateCycle = (dateType) => {
    let nowDate = [];
    props.ajax.post("/initDate/queryDateCycle", { dateType }).then((res) => {
      const { startDate, endDate } = res.data || {};
      console.log(startDate, endDate);
      nowDate = [moment(startDate), moment(endDate)];
      setDates(nowDate);
    });
  };
  useEffect(() => {
    if (!dates[0]) {
    getDateCycle(1);
    }
  }, [dates, props.ajax]);

  useEffect(() => {
    if (dates[0]) {
      setloading(true);
      props.ajax
        .post("/risk/control/api/continualImprovement", {
          startTime: moment(dates[0]).format("YYYY-MM-DD HH:ss:mm"),
          endTime: moment(dates[1]).endOf("D").format("YYYY-MM-DD HH:ss:mm"),
          type: switchValue || '0',
          departmentId,
          institutionId, //	制度ID
          employee: employee?.value, // 员工key
        })
        .then((res) => {
          setCards(res.data);
        })
        .finally(() => setloading(false));
    }
  }, [dates, departmentId, employee?.value, institutionId, props.ajax, switchValue]);

  // 遍历树选择
  const renderTreeNodes = (data, path) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            title={item.title}
            key={item.id}
            value={item.id}
            label={path + item.title}
            disabled={item.disabled}
          >
            {renderTreeNodes(item.children, path + item.title + "/")}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          value={item.id}
          title={item.title}
          key={item.id}
          label={path + item.title}
          disabled={item.disabled}
        />
      );
    });

  const renderMenus = (nodes) =>
    nodes.map((nd) => {
      if (nd.next && nd.next.length) {
        return (
          <Menu.SubMenu key={nd.nodeId} title={nd.nodeName} label={nd.nodeName}>
            {renderMenus(nd.next)}
          </Menu.SubMenu>
        );
      }
      return (
        <Menu.Item key={nd.nodeId} title={nd.nodeName} label={nd.nodeName}>
          {nd.nodeName}
        </Menu.Item>
      );
    });

  const handleSelectChange = (_value, option) => {
    console.log(_value, option);
    setEmployee(option);
  };

  useEffect(() => {
    //请求options下拉框数据
    props.ajax
      .get(
        "/risk/control/api/employeeList",
        {
          departmentId,
        },
        { noEmpty: false }
      )
      .then((res) => {
        if (res?.code === 0 && res?.data) {
          const arr = Object.entries(res.data).map(([key, value]) => ({
            label: value,
            value: key,
            unique: key,
          }));
          setOptions(arr);
        }
      });
  }, [departmentId, props.ajax]);

  useEffect(() => {
    (async () => {
      try {
        const res = await props.ajax.get("/risk/control/api/departmentTree");
        setTreeDData(res?.data || []);

        const resp = await props.ajax.get("/risk/control/api/institutionTree");
        setRules(resp.data);
      } catch (error) {
        message.error(error);
      }
    })();
  }, [props.ajax]);

  const onFixedClick = (e) => {
    setSwitchValue(e.key);
    setInstitutionId("");
  };
  const onRulesClick = (e) => {
    setInstitutionId(e.key);
    setSwitchValue("")
  };

  const handlePushDetail = (structId, idx, _userId) => {
    let name = "默认";
    let isChart = true;
    if (deptName) {
      name = deptName;
    }
    if (employee.value) {
      name = employee.label;
      isChart = "";
    }
    window.open(
      `/front/target-detail?name=${name}&structId=${structId}&indexIds=${idx}&userId=${
        employee.value || ""
      }&dateCycle=${
        moment(dates[0]).format("YYYY-MM-DD") +
        "~" +
        moment(dates[1]).format("YYYY-MM-DD")
      }&isChart=${isChart}&drillDownKey=${drillDownKey}`,
      "_blank"
    );
  };

  return (
    <PageContent fitHeight className={style.report}>
      <div className={style.headerTop}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <img src={peopleIcon} className={style.img} alt="icon" />
          </Breadcrumb.Item>
          <Breadcrumb.Item>员工持续改进</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className={style.formSetting} style={{ paddingBottom: 0 }}>
        <div className={style.form}>
          <Form.Item label="时间">
            <RangePicker
              value={dates}
              format="YYYY-MM-DD"
              onChange={(value) => {
                if (!value) return;
                setDates(value);
              }}
            />
          </Form.Item>
          <Form.Item style={{ marginLeft: "20px" }} label="所属部门">
            <TreeSelect
              placeholder="选择部门"
              showSearch={true}
              style={{ width: 300 }}
              allowClear={true}
              dropdownMatchSelectWidth={false}
              onChange={(id, label, extra) => {
                // setInitialValue("");
                setEmployee({});
                setDepartmentId(id);
                setDeptName(label);
                // console.log(id, label, extra);
                if (!id) {
                  setDrillDownKey("");
                }
              }}
              onSelect={(_v, nd) => {
                setDrillDownKey(nd.label);
              }}
              value={departmentId}
            >
              {renderTreeNodes(treeDData, "/")}
            </TreeSelect>
          </Form.Item>
          <Form.Item label="员工名称" style={{ marginLeft: "20px" }}>
            <Select
              style={{ width: 300 }}
              placeholder="选择员工"
              options={options}
              showSearch="true"
              filterOption={(inputValue, option)=>{
                return option.label.indexOf(inputValue) > -1;
              }}
              optionLabelProp="label"
              value={employee?.value}
              allowClear
              onChange={handleSelectChange}
            ></Select>
          </Form.Item>
        </div>

        <div style={{ display: "flex", paddingTop: 24 }}>
          <Form.Item>
            <Space>
              {dateButtonType.map((item) => {
                return (
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "rgb(246, 179, 7)",
                      borderColor: "rgb(246, 179, 7)",
                    }}
                    key={item.type}
                    size="small"
                    onClick={() => handleChangeDate(item.type,item.dateType)}
                  >
                    {item.name}
                  </Button>
                );
              })}
            </Space>
          </Form.Item>
        </div>
      </div>
      <div className={style.mainContainer}>
        <div className={style.sliderContainer}>
          <Menu
            mode="inline"
            onClick={onFixedClick}
            // defaultSelectedKeys={[switchValue]}
            selectedKeys={[switchValue]}
          >
            <Menu.Item key="0" icon={<AppstoreOutlined />}>
              全部
            </Menu.Item>
            <Menu.Item key="1" icon={<SettingOutlined />}>
              待办
            </Menu.Item>
          </Menu>
          <div className={style.menuContainer}>
            <PageContent fitHeight style={{ padding: 0, margin: "8px 0" }}>
              <Menu
                mode="inline"
                inlineIndent={20}
                // onClick={onRulesClick}
                onSelect={onRulesClick}
                selectedKeys={[institutionId]}
                // onSelect={(e) => console.log("onSelect", e)}
                // onSelect={(e) => setSelectedRule(e.key)}
                // onOpenChange={(e) => console.log("onOpenChange", e)}
                // onOpenChange={(e) => setSelectedRule(e[e.length - 1])}
              >
                {renderMenus(rules)}
              </Menu>
            </PageContent>
          </div>
        </div>
        {/* <div className={style.dataContainer}> */}
        <PageContent fitHeight className={style.dataItems}>
          {loading ? (
            <Spin spinning={true}></Spin>
          ) : cards.length ? (
            cards.map((o) => (
              <div
                key={o.requireId}
                className={style.cardContainer}
                onClick={() => handlePushDetail(o.structId, o.indexId)}
              >
                <div className={style.cardHeaderContainer}>
                  <div className={style.cardTopContainer}>
                    <div className={style.cardIconContainer}>
                      <img
                        src={!o.triggerCondition ? goodIcon : unruleIcon}
                        className={style.cardIcon}
                        alt="good"
                      />
                    </div>
                    <span className={style.cardValue}>{o.indexResult}</span>
                  </div>
                  <div
                    className={style.cardCheckContainer}
                    style={{
                      backgroundColor: !o.triggerCondition
                        ? "#E8FFEA"
                        : "#FFECE8",
                      color: !o.triggerCondition ? "#00B42A" : "#F53F3F",
                    }}
                  >
                    <img
                      src={!o.triggerCondition ? checkedIcon : uncheckIcon}
                      className={style.cardIcon}
                      style={{ marginRight: 4 }}
                      alt="good"
                    />
                    <span>{!o.triggerCondition ? "合格" : "不合格"}</span>
                  </div>
                </div>

                <Tooltip placement="bottom" title={o.requireName}>
                  <div className={style.cardLabel}>{o.requireName}</div>
                </Tooltip>
              </div>
            ))
          ) : (
            renderEmpty()
          )}
        </PageContent>
        {/* </div> */}
      </div>
    </PageContent>
  );
});
