export const CycleTypeEnum = [
  { name: "日", value: "1" },
  { name: "周", value: "2" },
  { name: "月", value: "3" },
  { name: "季度", value: "4" },
];
// limit数量
export const LIMIT = 9
export const dateButtonType = [
  {
    type: "currentWeek",
    name: "本周",
    dateType:7,
  },
  {
    type: "lastWeek",
    name: "上周",
    dateType:8,
  },
  {
    type: "currentMonth",
    name: "本月",
    dateType:1,
  },
  {
    type: "lastMonth",
    name: "上月",
    dateType:2,
  },
  {
    type: "currentQuarter",
    name: "本季度",
    dateType:3,
  },
  {
    type: "lastQuarter",
    name: "上季度",
    dateType:4,
  },
  {
    type: "lastThirtyDays",
    name: "近30天",
    dateType:20,
  },
  {
    type: "lastSevenDays",
    name: "近7天",
    dateType:30,
  },
  {
    type: "currentYear",
    name: "今年",
    dateType:5,
  },
  {
    type: "lastYear",
    name: "去年",
    dateType:6,
  },
];
